import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Confirmation from '../views/Confirmation.vue'
import Form from '../views/Form.vue'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Appointment from '../views/Appointment.vue'
import Mail from '../views/Mail.vue'
import NotFound from '../views/NotFound.vue'
import { auth } from '../db';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/confirmation/:id',
    name: 'Confirmation',
    component: Confirmation
  },
  {
    path: '/form',
    name: 'Form',
    component: Form,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/appointment',
    name: 'Appointment',
    component: Appointment,
  },
  {
    path: '/mail',
    name: 'mail',
    component: Mail,
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }  
})

router.beforeEach((to, from, next) => {
  const adminPages = ["Dashboard","Appointment"];
  if (adminPages.includes(to.name) && !auth.currentUser) next({ name: 'Login' });
  else next();
})

export default router
