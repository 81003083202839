<template>
  <v-dialog v-model="show" :fullscreen="mobile" hide-overlay transition="dialog-bottom-transition" scrollable>
    <v-card tile>
        <v-toolbar flat dark color="#ff1493" style="max-height: 56px;">
          <v-btn icon dark @click="close()"> <v-icon>mdi-close</v-icon> </v-btn>
          <v-toolbar-title>Enviar Email</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-text-field v-model="email" :label="`${ appointment.user.lastName }, ${ appointment.user.firstName }`"></v-text-field>
            <v-text-field v-model="subject" label="Título"></v-text-field>
            <v-textarea v-model="body" required outlined name="input-7-1" label="Comentarios" value="" ></v-textarea>
            <div v-for="image in imageList" :key="image.obj.name" class="preview">
              <v-btn icon dark @click="remove(image)" class="remove"  color="red"> <v-icon>mdi-close</v-icon> </v-btn>
              <img width="120px" height="auto" :src="image.url">
            </div>
            <div style="clear:both"></div>
            <input style="display: none" type="file" ref="input1" accept="image/*" capture="camera" @change="previewFiles">
            <v-flex class="text-center">
              <v-btn color="default" style="color:deeppink" @click="camera">
                <v-icon>mdi-camera</v-icon>
              </v-btn>
            </v-flex>
            <br/>
            <v-btn color="#ff1493" block class="mr-4 white--text" @click="send()">
                Enviar
            </v-btn>
          <v-divider></v-divider>
        </v-card-text>
    </v-card>
  </v-dialog>  
</template>
<script>

export default {
  name: "SendFile",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    appointment: {
      type: Object,
      required: true,
    },
  },
  created() {
      this.email = this.appointment.user.email;
  },
  data: function () {
    return {
      subject: null,
      email: null,
      body:null,
      mobile: window.innerWidth < 600,
      file: null,
      imageList: []
    };
  },
  methods: {
    camera(){
      this.$refs.input1.click()
    },
    remove(image){
      this.imageList = this.imageList.filter(i => i != image);
    },
    close() {
      this.$emit("close");
    },
    send() {
      this.$emit('send', {
        subject: this.subject,
        email: this.email,
        body: this.body,
        imageList: this.imageList
      });
    },
   previewFiles(event) {
      this.imageList.push({ 
        obj:event.target.files[0], 
        url: URL.createObjectURL(event.target.files[0])
      })
   }    
  },
};
</script>

<style scoped>
  .remove{
    position: absolute;
    top:5px;
    right: 5px;
    background: white;
    opacity: 0.3;

  }
  .preview{
    position: relative;
    width: 120px;
    height: auto;
    float: left;
    margin-right: 1px;
  }
</style>