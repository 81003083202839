<template>
  <div>
    <v-card class="mx-auto" elevation="2" app>
      <div style="text-align:center">
        <v-avatar  color="#ff1493"  rounded  size="256"> <img :src="pro.image"> </v-avatar>
      </div>
      <v-card-title class="d-block"> {{ pro.displayName }}</v-card-title>
      <v-card-text>
        <b>Valor de la consulta ${{ pro.price }} (pesos Argentinos)</b>
      </v-card-text>

      <v-card-text style="text-align:left;">
        <h3>Consulta Online: (leer condiciones)</h3>
        <br/>
        <div>
          <b>Importante:</b> Solo para consultas ginecológicas, <b>por este medio no atendemos embarazos</b><br/>
          La consulta online se llevará a cabo por video llamada por medio de la <a target="_blank" href="https://zoom.us/download#client_4meeting">aplicación Zoom</a>, 
          asegurate de tener la aplicación instalada, verificá tu conexión de internet y estate pendiente a tu celular cuando iniciemos la conversación para que las respuestas 
          sean fluidas ya que la consulta se dará por finalizada luego de 30 minutos iniciada la misma.
          <br/>
          <b>La consulta online no reemplaza la consulta presencial.</b>
          Debes tener en cuenta que en ginecología existen consultas que se deben completar con un examen ginecológico, el mismo es imposible de realizar online, por lo cual si tu consulta necesita ser presencial en consultorio o guardia ginecológica, es la indicación que vamos a realizar.
        </div>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-subtitle>Turnos Disponibles</v-card-subtitle>
      <date-selector v-if="days" v-model="date" :days="days"></date-selector>
      <v-progress-circular v-if="loading" :width="2" class="mb-1" indeterminate color="#ff1493"></v-progress-circular>
      <br/>
      <div v-for="row in schedulles" :key="row.join('')" class="mb-2 text-left centered-box pl-0 pl-md-2">
        <div class="box-wrapper">
          <v-btn v-for="sc in row" :key="sc" :disabled="!timeEnabled(sc)" :class="loading ? 'hidden': 'ml-4'" style="width:90px;"  @click="select(sc)">{{sc}}hs</v-btn>
        </div>
      </div>
      <br/>
    </v-card>
    <v-dialog v-model="errorDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Ginecología online</v-card-title>
        <v-card-text>{{errorMessage}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="load()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
    <v-overlay :value="fullLoading">
      <v-progress-circular :size="50" :width="3" class="mb-1" indeterminate color="#ff1493"></v-progress-circular>
    </v-overlay>
  </div>  
</template>

<script>
import DateSelector from '@/components/DateSelector.vue';
import { AppointmentsService } from '@/services/appointments.service';
import moment from 'moment';
import { getList, nextValidDay, groupBy, inTime } from '@/utils';

export default {
  name: "DoctorCard",
  components:{
    DateSelector
  },
  props: {
    pro: Object,
  },
  async created(){
    this.days = this.pro.schedulles ? Object.keys(this.pro.schedulles) : [];
  },
  data: function() {
    return{
      errorDialog:false,
      errorMessage:null,
      days: null,
      date: moment(),
      loading: false,
      fullLoading: false,
      uid: this.$store.getters.uid,
      taken:[]
    }
  },
  computed:{
    schedulles: function(){
      if(!this.pro.schedulles || !this.pro.schedulles[this.date.format('d')]){
        return [];
      }
      return groupBy(this.pro.schedulles[this.date.format('d')], 3);
    },
  },
  watch:{
    date: function(){
      this.load();
    }
  },
  methods: {
    async load(){
      this.errorDialog = false;
      this.loading = true;
      this.disableAll = false;
      const taken = await getList(AppointmentsService.getTakenByDate(this.pro.uid, null, this.date.format("YYYY-MM-DD")));
      this.taken = taken.map(t => {
        return t.time;
      })
      this.loading = false;
    },
    timeEnabled(sc){
      if(this.loading || this.taken.includes(sc) || this.taken.includes('*')){
        return false;
      }
      return inTime(this.date, sc);
    },
    showError(message){
      this.errorMessage = message;
      this.errorDialog = true;
    },
    async select(time){
      this.fullLoading = true;
      const {valid, msg} = await AppointmentsService.isValid(this.pro.uid, this.$store.getters.uid, this.date, time, this.pro);
      if(!valid){
        this.fullLoading = false;
        this.showError(msg);
        return;
      }
      this.fullLoading = false;
      this.$router.push({ path: 'form', query: {date: this.date.format('YYYY-MM-DD'), time, uid: this.pro.uid }});
    }
  }
};
</script>

<style scoped>
  .time-box{
    clear:both; 
    height:60px;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;    
  }
  .box-wrapper{
    max-width: 500px;
  }
  .centered-box{
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;    
  }
  .day{
    text-transform: capitalize;
  }
  .hidden{
    visibility: hidden;
  }
</style>
