<template>
  <div class="time-box" v-if="date">
    <v-btn class="float-left ml-6 mt-2" @click="prevDay()" :disabled="!prevDayEnabled"> <v-icon color="#ff1493">mdi-chevron-double-left</v-icon> </v-btn>
    <v-btn class="float-right mr-6 mt-2" @click="nextDay()" :disabled="!nextDayEnabled"> <v-icon color="#ff1493">mdi-chevron-double-right</v-icon> </v-btn>
    <span class="font-weight-bold day" v-text="date.format('dddd')"></span><br/>
    <span class="font-weight-bold" v-text="date.format('DD/MM/YYYY')"></span>
  </div>
</template>

<script>
import moment from 'moment';
import { getList, nextValidDay } from '@/utils';

export default {
  name: "DateSelector",
  props: {
    days: {
      type: Array,
      required: true,
    },
    min:{
      type: Number,
      default:0
    },
    max:{
      type: Number,
      default:10
    },
    defaultDate:{
      type: Object,
      default: null
    }
  },
  async created(){
    this.date = this.defaultDate || nextValidDay(this.date.add(-1,'days'), (this.days || []), 1);
    this.refresh();
  },
  data: function() {
    return{
      date: moment(),
    }
  },
  computed:{
    prevDayEnabled: function(){
      return this.date > moment().add(this.min, 'days')  ? true : false;
    },
    nextDayEnabled: function(){
      return this.date < moment().add(this.max, 'days') ? true : false;
    },
  },
  methods: {
    prevDay(){
      this.date = nextValidDay(this.date, this.days, -1);
      this.refresh();
    },
    nextDay(){
      this.date = nextValidDay(this.date, this.days, 1)
      this.refresh();
    },
    refresh(){
      this.$emit('input', this.date);
    }
  }
};
</script>

<style scoped>
  .time-box{
    clear:both; 
    height:60px;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
  .day{
    text-transform: capitalize;
  }
</style>
