import { functions, fs } from "../db";

export const EmailService = {

  sendEmail( email, appointmentUid, proUid, subject, body, images){
    const sendEmail = functions.httpsCallable("sendEmail");
    return sendEmail({ email, appointmentUid, proUid, subject, body, images });
  },
  getByAppointmentId(proUid, appointmentUid) {
      console.log(proUid, appointmentUid)
    return fs.collection("mails").where("appointmentUid", "==", appointmentUid).where("proUid", "==", proUid);
  },
}