<template>
  <div>
    <v-alert  v-if="oft" border="bottom" color="red darken-1" dark >
      Error: Link no disponible<br/>
      El link estará disponible 30 min antes de la llamada y durante la misma
    </v-alert>
    <v-card class="mx-auto" elevation="2" app v-if="confirmation">

      <v-card-title class="text-center" style="display: block">
        Consulta Confirmada
        <div class="text-caption">Código: {{ confirmation.paymentId }} </div>
      </v-card-title>
      
      <v-divider></v-divider>
      <div class="box-wrapper">
        <div class="box">
          <v-container class="grey lighten-5">
            <v-row no-gutters>
              <v-col cols="3">
                <v-icon class="mt-3" large color="green darken-1">mdi-check-circle</v-icon>
              </v-col>
              <v-col cols="9" class="text-left pl-0 pl-md-2">
                  <h4>{{ confirmation.displayName }}</h4>
                  <h4 class="day">{{ day }} {{ date }}</h4>
                  <h4>{{ confirmation.time }}hs</h4>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <v-divider></v-divider>
      <p  class="text-left mx-4 my-3 text-caption">
        Se le ha enviado un mail a {{ confirmation.email }} con los detalles para acceder a la consulta.<br/>
        También puede conectarse en el día y a la hora de la consulta por medio del siguiente link.
      </p>

      <div class="text-center mx-4 my-4">
          <v-btn :href="confirmation.callLink" color="#ff1493" style="color:white">Iniciar Video LLamada</v-btn>
      </div>
      <v-card-text style="text-align:left;">
        <h3>Importante:</h3>
        <div>
          La consulta online se llevará a cabo por video llamada por medio de la <a target="_blank" href="https://zoom.us/download#client_4meeting">aplicación Zoom</a>, asegurate de tener la aplicación instalada, verificá tu conexión de internet y estate pendiente a tu celular cuando iniciemos la conversación, para que las respuestas 
          sean fluidas ya que la consulta se dará por finalizada luego de 30 minutos iniciada la misma.<br/>
          <b>La consulta online no reemplaza la consulta presencial.</b> <br/>
          Debes tener en cuenta que en ginecología existen consultas se deben completar con un examen ginecológico, el mismo es imposible de realizar online, por lo cual si tu consulta necesita ser presencial en consultorio o guardia ginecológica, es la indicación que vamos a realizar.
          <br/>
          
        </div>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import { PaymentService } from '@/services/payment.service';

export default {
  async created(){
    const confirmation = await PaymentService.getConfirmation(this.$route.params.id).get();
    if(!confirmation){
      this.$router.push('/');
      return;
    }
    this.oft = this.$route.query.oft || false;
    this.confirmation = confirmation.data();
    this.date = moment(this.confirmation.date).format('DD/MM/YYYY');
    this.day = moment(this.confirmation.date).format('dddd');
  },
  data(){
    return {
      oft:false,
      confirmation: null,
      date:null,
      day:null
    }
  }

}
</script>

<style scoped>
  .day{
    text-transform: capitalize;
  }
  .box-wrapper{
    background: #fafafa;
  }
  .box{
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
</style>