<template>
  <v-dialog v-model="show" :fullscreen="mobile" hide-overlay transition="dialog-bottom-transition" scrollable>
    <v-card tile>
        <v-toolbar flat dark color="#ff1493" style="max-height: 56px;">
          <v-btn icon dark @click="close()"> <v-icon>mdi-close</v-icon> </v-btn>
          <v-toolbar-title>Datos del Paciente</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-list class="text-left">
              <v-list-item class="line-item">
                  <v-list-item-content>
                      <v-list-item-subtitle><b>Nombre:</b> {{ appointment.user.firstName }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item class="line-item">
                  <v-list-item-content>
                      <v-list-item-subtitle><b>Apellido:</b> {{ appointment.user.lastName }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item class="line-item">
                  <v-list-item-content>
                      <v-list-item-subtitle><b>DNI:</b> {{ appointment.user.DNI }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item class="line-item">
                  <v-list-item-content>
                      <v-list-item-subtitle><b>Teléfono:</b> {{ appointment.user.phone }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item class="line-item">
                  <v-list-item-content>
                      <v-list-item-subtitle><b>Email:</b> {{ appointment.user.email }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item class="line-item">
                  <v-list-item-content>
                      <v-list-item-subtitle><b>Fecha de Nacimiento:</b> {{ appointment.user.dateOfBirth }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item class="line-item">
                  <v-list-item-content>
                      <v-list-item-subtitle><b>Edad:</b> {{ appointment.user.age }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item class="line-item">
                  <v-list-item-content>
                      <v-list-item-subtitle><b>Ciudad Residencia:</b> {{ appointment.user.city }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item class="line-item">
                  <v-list-item-content>
                      <v-list-item-subtitle><b>Edad primera mestruación:</b> {{ appointment.user.ageFirstMest }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item class="line-item">
                  <v-list-item-content>
                      <v-list-item-subtitle><b>Fecha última mestruación:</b> {{ appointment.user.dateLastMest }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item class="line-item">
                  <v-list-item-content>
                      <v-list-item-subtitle><b>Inició relaciones sexuales:</b> {{ appointment.user.sexRelationships }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item class="line-item">
                  <v-list-item-content>
                      <v-list-item-subtitle><b>Toma anticonceptivos:</b> {{ appointment.user.contraceptive }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item class="line-item">
                  <v-list-item-content>
                      <v-list-item-subtitle><b>Embarazos:</b> {{ appointment.user.pregnancies }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item class="line-item">
                  <v-list-item-content>
                      <v-list-item-subtitle><b>Partos o cesáreas:</b> {{ appointment.user.childbirth }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item class="line-item">
                  <v-list-item-content>
                      <v-list-item-subtitle><b>Primera vez que se atiende ?:</b> {{ appointment.user.firstTime }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item class="line-item no-line">
                  <v-list-item-content>
                      <v-list-item-title>Consulta:</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item class="line-item no-line">
                  <v-list-item-content>
                      <v-list-item-subtitle>{{ appointment.user.reason }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item class="line-item no-line">
                  <v-list-item-content>
                      <v-list-item-subtitle>{{ appointment.user.reasonLong }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>              
          </v-list>
          <v-divider></v-divider>
        </v-card-text>
    </v-card>
  </v-dialog>  
</template>

<script>
export default {
  name: "AppointmentDetails",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    appointment: {
      type: Object,
      required: true,
    },
  },
  created() {},
  data: function () {
    return {
      mobile: window.innerWidth < 600,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
  .line-item{
    margin: 0;
    padding: 0;
    min-height: 20px;
    border-bottom: 1px solid #eee;
  }
  .no-line{
    border-bottom: 0;
  }

</style>