
import { storage } from '../db';

export const ImageService = {
    async upload(appointmentUid, image){
        const name = `${new Date().getTime()}_${image.name}`;
        const storageRef = await storage.ref(`docs/${appointmentUid}/${name}`).put(image);
        return storageRef.ref.getDownloadURL();
    }
}
