<template>
  <div>
    <v-card class="mx-auto" elevation="2" app v-if="appointment">
      <v-card-title class="text-left"> {{ appointment.user.lastName }}, {{ appointment.user.firstName }}</v-card-title>
      <v-card-subtitle class="text-left">
        {{ date.format("DD/MM/YYYY") }} - {{ appointment.time }}hs<br />
        {{ appointment.user.reason }}
      </v-card-subtitle>
      <v-divider class="mx-4"></v-divider>
      <v-list>
        <div v-for="(mail, index) in emails" :key="index">
          <v-list-item  @click="showEmail(mail)">
            <v-list-item-content>
              <v-list-item-title class="text-left" v-text="mail.message.subject"></v-list-item-title>
              <v-list-item-subtitle class="text-left" v-text="mail.date"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-icon v-if="mail.delivery.state=='SUCCESS'" color="green lighten-1">mdi-checkbox-marked-circle</v-icon>
                <v-icon v-if="mail.delivery.state=='ERROR'" color="red lighten-1">mdi-close-circle</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider class="mx-4" v-if="(index + 1) < emails.length"></v-divider>
        </div>
      </v-list>
    </v-card>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Ginecología online</v-card-title>
        <v-card-text>{{ message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog=false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <email-details v-if="selectedEmail" :mail="selectedEmail" :show="selectedEmail ? true: false" v-on:close="selectedEmail = null"></email-details>

    <v-overlay :value="fullLoading">
      <v-progress-circular
        :size="50"
        :width="3"
        class="mb-1"
        indeterminate
        color="#ff1493"
      ></v-progress-circular>
    </v-overlay>

  </div>
</template>

<script>
import { ProService } from "@/services/pro.service";
import { EmailService } from "@/services/email.service";
import { ImageService } from "@/services/image.service";
import { AppointmentsService } from "@/services/appointments.service";
import EmailDetails from "@/components/EmailDetails";
import { first, getList } from "../utils";
import moment from "moment";

export default {
  components: {
    EmailDetails
  }, 
  async created() {
    this.appointment = await first(
      AppointmentsService.getByUid(
        this.$store.getters.user.uid,
        this.$route.query.uid
      )
    );
    if (!this.appointment) {
      this.$router.go(-1);
    }
    this.date = moment(this.appointment.date);
    this.emails = await getList(EmailService.getByAppointmentId(this.$store.getters.user.uid, this.appointment.uid));
  },
  data: function () {
    return {
      date: moment(),
      selectedEmail: false,
      fullLoading: false,
      dialog: false,
      message: "",
      appointment: null,
      emails:[]
    };
  },
  methods: {
    showEmail(mail){
      this.selectedEmail = mail;
    },
    showMsg(msg){
      this.message = msg;
      this.dialog = true;
    },
  }
};
</script>

<style>
</style>