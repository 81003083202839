<template>
  <v-dialog v-model="show" :fullscreen="mobile" hide-overlay transition="dialog-bottom-transition" scrollable>
    <v-card tile>
        <v-toolbar flat dark color="#ff1493" style="max-height: 56px;">
          <v-btn icon dark @click="close()"> <v-icon>mdi-close</v-icon> </v-btn>
          <v-toolbar-title>Reenviar email de confirmación</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-text-field v-model="email" :label="`${ appointment.user.lastName }, ${ appointment.user.firstName }`"></v-text-field>      
            <v-btn color="#ff1493" block class="mr-4 white--text" @click="send()">
                Enviar
            </v-btn>
          <v-divider></v-divider>
        </v-card-text>
    </v-card>
  </v-dialog>  
</template>
<script>
export default {
  name: "ConfirmationEmail",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    appointment: {
      type: Object,
      required: true,
    },
  },
  created() {
      this.email = this.appointment.user.email;
  },
  data: function () {
    return {
      email: null,
      mobile: window.innerWidth < 600,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    send() {
      this.$emit('send', this.email);
    }
  },
};
</script>

<style scoped>

</style>