<template>
  <v-app id="app">
    <v-app-bar app class="d-sm-none" style="color:white; background:deeppink; ">
      <v-btn v-if="hasBack" icon class="white--text" @click="goBack()"><v-icon>mdi-arrow-left</v-icon></v-btn>        
      <v-toolbar-title  class="pl-0"><a href="/"><img class="float-left" src="./assets/logos/cgap.png" height="25px"></a> <span style="margin-left:10px;font-size: 17px;">Ginecología Online</span></v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-app-bar app class="d-none d-sm-block text-left" style="color:white; background:deeppink; ">
      <v-container>
        <v-toolbar-title  class="pl-0"><a href="/"><img class="float-left" src="./assets/logos/cgap.png" height="25px"></a> <span style="margin-left:10px;font-size: 17px;">Ginecología Online</span></v-toolbar-title>
      </v-container>
    </v-app-bar>
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer app>
    </v-footer>
  </v-app>
</template>

<script>
import { auth } from './db';

export default {
  
  created(){
    this.loggedIn = this.$store.getters.user ? true : false;
  },
  data: function(){
    return {
      loggedIn: false
    }
  },
  methods:{
    goBack(){
      this.$router.go(-1);
    },
    async logout(){
      this.loggedIn = false;
      await auth.signOut();
      this.$router.push('login');
    }
  },
  computed:{
    hasBack(){
      return ['Dashboard','Mail','Home','Login'].includes(this.$route.name) ? false : true;
    }
  }
}
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
