<template>
  <div>
    <h2 v-if="pro">{{ pro.displayName }}</h2>
    <h4 class="day" v-if="date">{{ date.format('dddd') }} {{ date.format('DD/MM/YYYY') }} - {{ time }}hs</h4>
    <br/>
    <h4 v-if="pro">Valor de la consulta ${{ pro.price }} (pesos Argentinos)</h4>
    <v-form ref="form" lazy-validation>
      <v-text-field :rules="rules.required" required v-model="userForm.firstName" label="Nombre"></v-text-field>      
      <v-text-field :rules="rules.required" required v-model="userForm.lastName" label="Apellido"></v-text-field>      
      <v-text-field :rules="rules.required" required v-model="userForm.DNI" label="DNI"></v-text-field>      
      <p class="text-left mb-1">Teléfono:</p>
      <VuePhoneNumberInput :rules="rules.required" :error="!phoneObj || !phoneObj.isValid" class="mb-4" v-model="phone" :translations="{
          countrySelectorLabel: 'Código de País',
          countrySelectorError: 'Elija un País',
          phoneNumberLabel: 'Número de teléfono',
          example: 'Ejemplo: ',
          phoneNumberLabel : 'Número de Teléfono'
        }" default-country-code="AR" :required="true" @update="onUpdate" />
      <v-text-field :rules="rules.emailRules" required v-model="userForm.email" label="Email"></v-text-field>      

      <v-menu
        ref="modal2"
        v-model="modal2"
        :close-on-content-click="false"
        transition="scale-transition"
        min-width="auto"
        persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="userForm.dateOfBirth"
            label="Fecha de nacimiento"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker locale="es-ES" ref="picker" v-model="userForm.dateOfBirth" :max="new Date().toISOString().substr(0, 10)">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="$refs.modal2.save(userForm.dateOfBirth)" > OK </v-btn>
        </v-date-picker>
      </v-menu>

      <v-text-field :rules="rules.required" required v-model="userForm.city" label="Ciudad de residencia"></v-text-field>      
      
      <!-- <p class="text-left font-weight-bold">Motivo de Consulta:</p> -->
      <v-text-field :rules="rules.required" required v-model="userForm.reason" label="Motivo de consulta"></v-text-field>
      <v-textarea
          v-model="userForm.reasonLong"
          :rules="rules.required" 
          required
          outlined
          name="input-7-1"
          label="Breve resumen de tu motivo de consulta"
          value=""
      ></v-textarea>

      <p class="text-left font-weight-bold">Antecedentes ginecológicos:</p>

      <v-text-field :rules="rules.required" required v-model="userForm.ageFirstMest" label="Edad primer mestruación"></v-text-field>
      
      <v-dialog ref="dialog" v-model="modal" :return-value.sync="userForm.dateLastMest" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :rules="rules.required" required v-model="userForm.dateLastMest" label="Fecha última mestruación" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker locale="es-ES" v-model="userForm.dateLastMest" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="$refs.dialog.save(userForm.dateLastMest)" > OK </v-btn>
          </v-date-picker>
      </v-dialog>

      <v-text-field :rules="rules.required" required v-model="userForm.contraceptive" label="Método anticonceptivo utilizado?"></v-text-field>
      <v-text-field :rules="rules.required" required v-model="userForm.pregnancies" label="Embarazos tuviste? Cuantos?"></v-text-field>
      <v-text-field :rules="rules.required" required v-model="userForm.lastPapanicolaou" label="Fecha último Papanicolau"></v-text-field>

      <p class="text-left">Ha iniciado relaciones sexuales?</p>
      <v-radio-group  :rules="rules.required" required v-model="userForm.sexRelationships" :mandatory="false">
        <v-radio label="Si" value="Si"></v-radio>
        <v-radio label="No" value="No"></v-radio>
      </v-radio-group>
      
      <p class="text-left">Partos o cesáreas?</p>
      <v-radio-group  :rules="rules.required" required v-model="userForm.childbirth" :mandatory="false">
        <v-radio label="Si" value="Si"></v-radio>
        <v-radio label="No" value="No"></v-radio>
      </v-radio-group>

      <p class="text-left">Es primera vez que te atendes con la Dra. / Dr. ?</p>
      <v-radio-group  :rules="rules.required" required v-model="userForm.firstTime" :mandatory="false">
        <v-radio label="Si" value="Si"></v-radio>
        <v-radio label="No" value="No"></v-radio>
      </v-radio-group>

      <v-btn color="#ff1493" block class="mr-4 white--text" @click="getPaymentLink()">
        Aceptar
      </v-btn>
    </v-form>
    <v-overlay :value="fullLoading">
      <v-progress-circular :size="50" :width="3" class="mb-1" indeterminate color="#ff1493"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="errorDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Ginecología online</v-card-title>
        <v-card-text>{{errorMessage}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="errorDialog=false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>     
  </div>

</template>

<script>

import { ProService } from '@/services/pro.service';
import { AppointmentsService } from '@/services/appointments.service';
import { PaymentService } from '@/services/payment.service';
import VuePhoneNumberInput from 'vue-phone-number-input';
import { calculateAge } from '@/utils';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import moment from 'moment';

export default {
    components:{
      VuePhoneNumberInput
    },
    async created(){
      this.time = this.$route.query.time;
      this.pro = (await ProService.getById(this.$route.query.uid).get()).data();
      this.date = moment(this.$route.query.date);
      this.uid = this.$store.getters.uid;
      const {valid} = await AppointmentsService.isValid(this.pro.uid, this.$store.getters.uid, this.date, this.time, this.pro);
      if(!valid){
        this.$router.push('/');
        return;
      }
      await AppointmentsService.releaseAppointments(this.uid);
      await AppointmentsService.takeAppointment(this.pro.uid, this.uid, this.date.format('YYYY-MM-DD'), this.time);
    },

    data: ()=>({
      phone: '',
      phoneObj: null,
      fullLoading:false,
      date:null,
      pro:null,
      time:null,
      modal:null,
      modal2:null,
      uid:null,
      valid: true,
      errorDialog: false,
      errorMessage: '',
      rules:{
        required:[
          v => !!v || 'Por favor complete este campo',
        ],
        emailRules: [
          v => !!v || /.+@.+\..+/.test(v) || 'Por favor complete este campo',
          v => /.+@.+\..+/.test(v) || 'El email debe ser un email válido',        
        ],
      },
      userForm:{
          firstName: null,
          lastName: null,
          DNI: null,
          phone: null,
          email: null,
          age: null,
          dateOfBirth: null,
          city: null,
          reason: null,
          reasonLong: null,
          ageFirstMest: null,
          dateLastMest: null,
          sexRelationships: null,
          contraceptive:null,
          pregnancies:null,
          firstTime:null,
          childbirth:null,
      }
    }),
    watch: {
      modal2 (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    },
    methods: {
      showMessage(msg){
        this.errorMessage = msg;
        this.errorDialog =true;
      },
      onUpdate(payload){
        this.phoneObj = payload;
      },
      async getPaymentLink(){
        
        this.$refs.form.validate()
        if(!this.$refs.form.validate()){
          this.showMessage("Verifique que todos los campos hayan sido completados correctamente");
          return;
        }
        if(!this.phoneObj || !this.phoneObj.isValid){
          this.showMessage("Verifique que el número de teléfono sea correcto");
          return;
        }
        this.userForm.phone = `+${this.phoneObj.countryCallingCode} ${this.phoneObj.phoneNumber}`
        this.fullLoading = true;
        if(!await AppointmentsService.isValid(this.pro.uid, this.uid, this.date, this.time, this.pro)){
          this.fullLoading = false;
          this.showMessage("Tomó demasiado tiempo en completar el formulario y el turno ya fué tomado por alguien más, le pedimos disculpas, por favor seleccione un horario distinto");
          return;
        }
        const appointment = {
          date: this.date.format('YYYY-MM-DD'),
          time: this.time,
          source: "web",
          user:{
            uid: this.uid,
            ...this.userForm,
            firstName: this.userForm.firstName.trim(),
            lastName: this.userForm.lastName.trim(),
            email:this.userForm.email.trim(),
            DNI:this.userForm.DNI.trim(),
            age: calculateAge(this.userForm.dateOfBirth)
          },
          pro:{
            uid: this.pro.uid,
          }
        };
        try{
          const resp = await PaymentService.getLink(appointment);
          window.location = resp.data.paymentUrl;
        }catch(e){
          this.fullLoading = false;
          this.showMessage("Ha ocurrido un error, Verifique todos los datos ingresados sean correctos e intente nuevamente más tarde");
        }
      }
    }
}

</script>

<style scoped>
  .day{
    text-transform: capitalize;
  }
</style>


