import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    uid: null,
    user: null,
    date: null,
  },
  getters: {
    uid: (state) =>{
      return state.uid;
    },
    user: (state) => {
      return state.user ? state.user : false;
    },
    date: (state) => {
      return state.date;
    }
  },
  mutations: {
    setUid(state, payload) {
      state.uid = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setDate(state, date) {
      state.date = date;
    },
  },
});
