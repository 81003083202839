import Vue from 'vue';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import { store } from './store';
import VueCookies from 'vue-cookies'
import { v4 as uuidv4 } from 'uuid';
import { auth } from './db';

Vue.use(VueCookies)

Vue.config.productionTip = false;

import moment from 'moment';
moment.locale('es-ES');

Vue.$cookies.config('30d')
let uid = Vue.$cookies.get('guess_uid');

if(!uid){
  uid = uuidv4();
  Vue.$cookies.set('guess_uid', uid);
}
store.commit('setUid',uid);

const initVue = () => {
  window.document.getElementsByTagName('body')[0].className="loaded";
  new Vue({
    store,
    vuetify,
    router,
    render (h) { return h(App) }
  }).$mount('#app');  
}

auth.onAuthStateChanged(function(user) {
  if(user) {
    store.commit('setUser', user);
  }else{
    store.commit('setUser', null);
  }
  initVue();
});
