<template>
  <div>
    <v-card class="mx-auto" elevation="2" app v-if="appointment">
      <v-card-title class="text-left"> {{ appointment.user.lastName }}, {{ appointment.user.firstName }}</v-card-title>
      <v-card-subtitle class="text-left">
        {{ date ? date.format("DD/MM/YYYY") : "" }} - {{ appointment.time }}hs<br />
        {{ appointment.user.reason }}
      </v-card-subtitle>
      <v-divider class="mx-4"></v-divider>
      <v-list>
        <v-list-item @click="showDetails = true">
          <v-icon class="mr-2" color="#ff1493">mdi-clipboard-list-outline</v-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">Datos de la consulta</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item :disabled="!appointment.zoom" :href="appointment.zoom ? appointment.zoom.start_url: ''" target="_blank">
          <v-icon class="mr-2" color="#ff1493">mdi-video-outline</v-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">
              Iniciar video llamada
              <v-progress-circular v-if="loadingZoom" :size="20" :width="2" class="mb-1" indeterminate color="#ff1493"></v-progress-circular>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item   @click="showSendFile = true">
          <v-icon class="mr-2" color="#ff1493">mdi-email-send-outline</v-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">Enviar email</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item  @click="goMails()">
          <v-icon class="mr-2" color="#ff1493">mdi-email-check-outline</v-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">Ver Emails enviados</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item  @click="showConfirmationEmail = true">
          <v-icon class="mr-2" color="#ff1493">mdi-link-variant</v-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">Reenviar link de llamada</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Ginecología online</v-card-title>
        <v-card-text>{{ message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog=false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <appointment-details v-if="appointment" :appointment="appointment" :show="showDetails" v-on:close="showDetails = false"></appointment-details>
    <confirmation-email v-if="appointment" :appointment="appointment" :show="showConfirmationEmail" v-on:close="showConfirmationEmail = false" v-on:send="sendConfirmation($event)"></confirmation-email>
    <send-file v-if="appointment" :appointment="appointment" :show="showSendFile" v-on:close="showSendFile = false" v-on:send="sendFile($event)"></send-file>

    <v-overlay :value="fullLoading">
      <v-progress-circular
        :size="50"
        :width="3"
        class="mb-1"
        indeterminate
        color="#ff1493"
      ></v-progress-circular>
    </v-overlay>

  </div>
</template>

<script>
import { ProService } from "@/services/pro.service";
import { EmailService } from "@/services/email.service";
import { ImageService } from "@/services/image.service";
import { AppointmentsService } from "@/services/appointments.service";
import AppointmentDetails from "@/components/AppointmentDetails";
import ConfirmationEmail from "@/components/ConfirmationEmail";
import SendFile from "@/components/SendFile";
import { first, zoomTime } from "../utils";
import moment from "moment";

export default {
  components: {
    AppointmentDetails,
    ConfirmationEmail,
    SendFile
  }, 
  async created() {
    this.appointment = await first(
      AppointmentsService.getByUid(
        this.$store.getters.user.uid,
        this.$route.query.uid
      )
    );
    if (!this.appointment) {
      this.$router.go(-1);
    }
    this.appointment.zoom = false;
    if (!this.appointment.zoom && zoomTime(this.appointment.date, this.appointment.time)){
      this.loadingZoom = true;
      AppointmentsService.getZoomLink(this.appointment.uid).then(resp =>{
        this.loadingZoom=false;
        this.appointment.zoom = { start_url: resp.data };
      });
    }
    this.date = moment(this.appointment.date);
  },
  data: function () {
    return {
      date: null,
      loadingZoom: false,
      showConfirmationEmail: false,
      showDetails: false,
      showSendFile: false,
      fullLoading: false,
      dialog: false,
      message: "",
      appointment: null,
    };
  },
  methods: {
    showMsg(msg){
      this.message = msg;
      this.dialog = true;
    },
    goMails(){
      this.$router.push({ path: 'mail', query: {uid: this.appointment.uid}});
    },
    async sendFile(data){
      try{
        this.showSendFile = false;
        this.fullLoading = true;
        const urlList = [];
        const imagePromises = []
        data.imageList.forEach(async (img) => {
          imagePromises.push(ImageService.upload(this.appointment.uid, img.obj)) 
        })
        const resp = await Promise.all(imagePromises);
        await EmailService.sendEmail(data.email, this.appointment.uid, this.$store.getters.user.uid, data.subject, data.body, resp);
        this.fullLoading = false;
        this.showMsg('El email se envió con éxito!')
      }catch(e){
        this.showMsg('Ha ocurrido un Error al enviar el email!')
      }
    },
    async sendConfirmation(email){
      this.showConfirmationEmail = false;
      await AppointmentsService.sendConfirmation(email,this.appointment.uid);
      this.showMsg('El email se envió con éxito!')
    }
  }
};
</script>

<style>
</style>