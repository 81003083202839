import moment from 'moment';

export const first = async (ref) => {
    const list = await getList(ref);
    return list.length > 0 ? list[0] : null;
}

export const getList = async (ref) =>{
    const resp = await ref.get();
    const response = [];
    resp.forEach(d => {
      response.push(d.data());
    });
    return response;
}

export const nextValidDay = (date, days, direction=1) =>{
    if(days.length == 0){
        return false;
    }
    for(let i=1; i<8; i++){
        date = date.add(direction, 'days');
        if(days.includes(date.format('d'))){
            return moment(date);
        }
    }
    return false;
}

export const zoomTime = (dateStr, time, startMargin=-65, finishMargin=90)=>{
    const appDateTime = moment(`${dateStr} ${time}`);
    const startTime = appDateTime.clone().add(startMargin, 'minutes');
    const endTime = appDateTime.clone().add(finishMargin, 'minutes');
    const now = moment();
    return now > startTime && now < endTime;
}

export const inTime = (date, time, margin=5)=>{
    if(!date){
        return;
    }
    const today = moment().format('YYYY-MM-DD');
    if(date.format('YYYY-MM-DD') < today){
        return false;
    }
    if(date.format('YYYY-MM-DD') > today){
        return true;
    }
    const tmp = time.split(':');
    const hour = parseInt(date.format('H'));
    const min = parseInt(date.format('m'));

    if(hour < parseInt(tmp[0])){
        return true;
    }
    if(hour == parseInt(tmp[0]) && (min + margin)  < parseInt(tmp[1]) ){
        return true;
    }
    return false;
}

export const groupBy = (list, groupBy) => {
    const rows = [];
    let group = [];
    list.forEach(sc =>{
      group.push(sc);
      if(group.length == groupBy){
        rows.push(group);
        group = [];
      }
    });
    if(group.length!=0){
      rows.push(group);
    }
    return rows;
}

export const calculateAge = (birthday) => { 
    console.log(birthday)
    const bDate = new Date(birthday)
    const ageDifMs = Date.now() - bDate.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}