import { functions, fs } from "../db";

export const PaymentService = {
  getLink(appointment) {
    const paymentLink = functions.httpsCallable("paymentLink");
    return paymentLink({ appointment });
  },

  getConfirmation(confirmationId) {
    return fs.collection("confirmation").doc(confirmationId);
  },
};
