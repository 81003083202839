<template>
  <v-dialog v-model="show" :fullscreen="mobile" hide-overlay transition="dialog-bottom-transition" scrollable>
    <v-card tile>
        <v-toolbar flat dark color="#eee" class="black--text" style="max-height: 56px;">
          <v-btn icon color="black" @click="close()"> <v-icon>mdi-close</v-icon> </v-btn>
          <v-toolbar-title class="text-left font-small" v-html="mail.message.subject + '<br/> Enviado el ' + mail.date"></v-toolbar-title>
        </v-toolbar>
        <v-card-text class="text-left" v-html="mail.message.html"></v-card-text>
    </v-card>
  </v-dialog>  
</template>
<script>
import moment from "moment";

export default {
  name: "EmailDetails",
  props: {
    show: {
      type: Boolean,
      default: false,
    },    
    mail: {
      type: Object,
      required: true,
    },
  },
  created() {
  },
  data: function () {
    return {
      mobile: window.innerWidth < 600,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
  .remove{
    position: absolute;
    top:5px;
    right: 5px;
    background: white;
    opacity: 0.3;

  }
  .preview{
    position: relative;
    width: 120px;
    height: auto;
    float: left;
    margin-right: 1px;
  }
  .font-small{
    font-size:12px;
  }
</style>