import { functions, fs, firestore } from "../db";
import { inTime, first } from '../utils';

export const AppointmentsService = {
  getByDate(proUid, date) {
    return fs
      .collection("appointments")
      .where("proUid", "==", proUid)
      .where("date", "==", date)
      .orderBy("time");
  },

  getByUid(proUid, uid) {
    return fs.collection("appointments").where("proUid", "==", proUid).where("uid", "==", uid);
  },
  
  getZoomLink(appointmentUid){
    const zoomLink = functions.httpsCallable("zoomLink");
    return zoomLink({ appointmentUid });
  },
  
  sendConfirmation( email, appointmentUid){
    const sendConfirmation = functions.httpsCallable("sendConfirmation");
    return sendConfirmation({ email, appointmentUid });
  },

  getTakenByDate( proUid, uid = null, date = null, time = null, temp = null ) {
    let ref = fs.collection(`/appointmentsTaken`);
    ref = ref.where("proUid", "==", proUid);
    if(date){
        ref = ref.where("date", "==", date);
    }
    if (time) {
        ref = ref.where("time", "==", time);
    }
    if (uid) {
        ref = ref.where("uid", "==", uid);
    }
    if (temp) {
        ref = ref.where("temp", "==", true);
    }
    return ref;
  },
  
  async releaseAppointments(uid) {
      const ref = await fs.collection(`/appointmentsTaken`).where("uid", "==", uid).where("temp","==",true).get();
      ref.forEach((snap) => snap.ref.delete());
  },
  
  takeAppointment(proUid, uid, date, time, temp = true) {
      return fs.collection(`/appointmentsTaken`).add({
          proUid,
          uid,
          date,
          time,
          temp,
          createdAt: firestore.FieldValue.serverTimestamp(),
      });
  },
  
  async isTaken(proUid, uid, date, time){
      const resp = await first(this.getTakenByDate(proUid, null,  date, time));
      if(!resp){
          return false;
      }
      return resp.data().uid != uid;
  },
  
  async isValid(proUid, uid, date, time, pro){
      if(!inTime(date, time)){
          return {valid: false, msg: 'El horario del turno ya pasó o esta muy cerca para ser seleccionado, por favor seleccione otro turno'};
      }
      if(await this.isTaken(proUid, date.format('YYYY-MM-DD'), time)){
          return {valid: false, msg: 'El turno ha sido ocupado por alguien más, seleccione otro turno o intente nuevamente mas tarde'};
      }
      const timesAllowed = pro.schedulles[date.format('d')]
      if(!timesAllowed || !timesAllowed.includes(time)){
        return {valid: false, msg: 'El turno ha sido ocupado por alguien más, seleccione otro turno o intente nuevamente mas tarde'};
      }

      return {valid:true, msg: 'ok'};
  }  
};
