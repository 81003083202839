<template>
  <div>
    <div v-for="pro in professionals" :key="pro.name">
      <doctor-card :pro="pro" /><br/>
    </div>
  </div>
</template>

<script>
import DoctorCard from '@/components/DoctorCard.vue';
import { ProService } from '@/services/pro.service';
import { AppointmentsService } from '@/services/appointments.service';

export default {
  name: 'Home',
  components: {
    DoctorCard
  },
  data:() => {
    return {
      professionals:[]
    }
  },
  async created(){
    await AppointmentsService.releaseAppointments(this.$store.getters.uid);
    const resp = await ProService.getProfessionals();
    resp.forEach(pro => {
      this.professionals.push(pro.data())
    })
  },
 
}
</script>

<style>

</style>
