import { fs } from '../db';

export const ProService = {

    getProfessionals(){
        return fs.collection('professionals').where("pro","==",true).orderBy('order').get()
    },

    getById(uid){
        return fs.collection('professionals').doc(uid);
    }
}