<template>
  <div>
    <date-selector v-if="days" v-model="date" :min="-365" :max="10" :days="days" :defaultDate="defaultDate"></date-selector>
    <v-progress-circular v-if="loading" :width="2" class="mb-1" indeterminate color="#ff1493"></v-progress-circular>
    <v-card class="mx-auto" max-width="500" v-if="!loading && !fullLoading">
      <v-list two-line>
          <template v-for="(item, index) in appointments">
            <v-list-item :key="item.uid" @click="select(item.uid)">
              <v-list-item-content>
                <v-list-item-title class="text-left">{{ item.time }}hs - {{ item.user.lastName}} {{ item.user.firstName }}</v-list-item-title>
                <v-list-item-subtitle class="text-left">{{ item.user.reason }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="index < appointments.length - 1" :key="index"></v-divider>
          </template>
      </v-list>
      <div class="pb-3" v-if="!loading && !fullLoading && appointments.length==0">No hay consultas para este día</div>
    </v-card>
    <v-overlay :value="fullLoading">
      <v-progress-circular
        :size="50"
        :width="3"
        class="mb-1"
        indeterminate
        color="#ff1493"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { ProService } from "@/services/pro.service";
import { AppointmentsService } from "@/services/appointments.service";
import { auth } from "../db";
import { getList } from "../utils";
import DateSelector from "@/components/DateSelector.vue";
import moment from "moment";

export default {
  name: "Dashboard",
  components: {
    DateSelector,
  },
  async created() {
    this.fullLoading = true;
    if(!this.$store.getters.user){
      this.$router.go(-1);
      return;
    }
    this.pro = (
      await ProService.getById(this.$store.getters.user.uid).get()
    ).data();
    this.fullLoading = false;
    if (!this.pro || this.pro.pro != true) {
      await auth.signOut();
      this.$router.go(-1);
      return;
    }
    this.defaultDate = this.$store.state.date;
    this.days = this.pro.schedulles ? Object.keys(this.pro.schedulles) : [];
  },
  data: function () {
    return {
      defaultDate:null,
      days: null,
      date: moment(),
      fullLoading: false,
      loading: false,
      pro: null,
      selected:[],
      appointments:[],
    };
  },
  watch: {
    date: function () {
      this.$store.commit('setDate', this.date);
      this.load();
    },
  },
  methods: {
    async load() {
      this.loading = true;
      this.appointments = await getList(
        AppointmentsService.getByDate(
          this.pro.uid,
          this.date.format("YYYY-MM-DD")
        )
      );
      this.loading = false;
    },
    select(uid){
      this.$router.push({ path: 'appointment', query: {uid}});
    }
  },
};
</script>

<style scoped>
</style>