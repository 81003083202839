<template>
  <div>
    <v-card class="mx-auto" elevation="2" app>
      <v-card-title class="d-block"> Ingreso de médicos</v-card-title>
      <v-card-text>
        <form @submit.prevent="login()">
          <v-text-field
            type="email"
            prepend-icon="mdi-account"
            v-model="userName"
            label="Usuario"
          ></v-text-field>
          <v-text-field
            type="password"
            prepend-icon="mdi-lock"
            v-model="password"
            label="Clave"
          ></v-text-field>
          <v-divider class="mx-4"></v-divider>
          <v-btn
            type="submit"
            color="#ff1493"
            block
            class="mr-4 white--text"
            :disabled="!userName || !password"
          >
            Ingresar
          </v-btn>
        </form>
      </v-card-text>
    </v-card>
    <v-dialog v-model="errorDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Ginecología online</v-card-title>
        <v-card-text>{{ errorMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="errorDialog = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="fullLoading">
      <v-progress-circular
        :size="50"
        :width="3"
        class="mb-1"
        indeterminate
        color="#ff1493"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { auth } from "../db";

export default {
  created() {
  },
  data: function () {
    return {
      errorMessage: "",
      errorDialog: false,
      fullLoading: false,
      userName: null,
      password: null,
    };
  },
  methods: {
    showMessage(msg) {
      this.errorMessage = msg;
      this.errorDialog = true;
    },
    async login() {
      this.fullLoading = true;
      try {
        const resp = await auth.signInWithEmailAndPassword(
          this.userName,
          this.password
        );
        this.fullLoading = false;
        this.$router.push("dashboard");
      } catch (e) {
        this.fullLoading = false;
        this.showMessage("Usuario o Clave Incorrectos");
      }
    },
  },
};
</script>

<style scoped>
</style>