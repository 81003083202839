import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/storage';


const firebaseConfig = {
    apiKey: "AIzaSyCBVPhH-3wDQNiIUKIiDn7xeD_s3Eznxvw",
    authDomain: "ginecoonline-2a369.firebaseapp.com",
    databaseURL: "https://ginecoonline-2a369.firebaseio.com",
    projectId: "ginecoonline-2a369",
    storageBucket: "ginecoonline-2a369.appspot.com",
    messagingSenderId: "169031307668",
    appId: "1:169031307668:web:7995d5e163ea325eabbf81"
  };

firebase.initializeApp(firebaseConfig)
export const fs = firebase.firestore();
export const auth = firebase.auth();
export const firestore = firebase.firestore;
export const functions = firebase.functions();
export const storage = firebase.storage();